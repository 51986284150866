import * as React from 'react'
import styled from 'styled-components'
import { TextBody, TextHeader } from 'components/atoms/typography'
import { SEO } from 'components/organisms/SEO'
import { Layout } from 'components/Layout'
import { MediaQueries } from 'styles'
import { HeadFC } from 'gatsby'

const NotFoundPage = () => {
  return (
    <>
      <SEO pageName="404" />
      <Layout>
        <PageWrapper>
          <ContentWrapper>
            <TextHeader size='large'>404</TextHeader>
            <StyledTextBody size="large">
              Error 404. The page you are looking for doesn&apos;t exist or has been moved.
            </StyledTextBody>
          </ContentWrapper>
        </PageWrapper>
      </Layout>
    </>
  )
}

const PageWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: inherit;
  padding: 80px 24px;
  background-color: ${({ theme }) => theme.colors.white};
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 442px;
  gap: 75px;

  ${MediaQueries.medium} {
    gap: 48px;
  }
`

const StyledTextBody = styled(TextBody)`
  margin-top: 10px;
  color: ${({ theme }) => theme.colors.textLight};
  text-align: center;
`

export default NotFoundPage

export const Head: HeadFC = () => <SEO pageName="404" />
